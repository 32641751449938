.online-badge {
	position: relative;
	.ProfilePicture {
		aspect-ratio: 1;
		background-color: #ccc;

		&.ai {
			box-shadow: 0 0 4px 2px rgba(99, 233, 243, 0.9);
			background-color: rgba(99, 233, 243, 0.6);
		}
	}
	.MuiBadge-dot {
		z-index: 2;
		background: green;
	}
}
