.CompanyAvailabilityDialog {
	.MuiFormControlLabel-root {
		margin-right: 0;

		.MuiFormControlLabel-label {
			flex-grow: 1;

			.MuiListItem-container {
				list-style-type: none;

				.MuiListItemSecondaryAction-root {
					right: 0;
				}
			}
		}
	}
}
