.BefareLandingScreen {
	margin-top: var(--top-app-bar-height);
	margin-bottom: var(--top-app-bar-height);

	.BefareLandingScreen__employees {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.employee-card {
			margin: 1rem 1rem 0 0;
			display: flex;
			flex-direction: column;
			flex-shrink: 1;
			flex-grow: 1;
			width: 30%;
			max-width: 350px;

			.ProfilePicture {
				height: 150px;
				width: 150px;
				background-size: cover;
				margin: 1rem auto;
			}
		}
	}
}
