.LoginComponent {
	margin-bottom: 1rem;

	h3 {
		font-size: 2.5rem;
	}
	.login-input {
		background: rgba(0, 0, 0, 0.1);
		color: #424242;

		* {
			color: #424242;
		}
	}

	.ResendVerificationCodeButton {
		&.Mui-disabled {
			color: #ccc;
		}
	}
	.WorkspaceList {
		background: rgba(0, 0, 0, 0.1);
		padding: 0.5rem;
		color: #424242;
	}
}
