:root {
	--column-count: 4;
	--gutter-width: 16px;
	--margin-width: 16px;
	--body-width: calc(100vw - (var(--margin-width) * 2));
	--column-width: calc((var(--body-width) - (var(--gutter-width) * (var(--column-count) - 1))) / var(--column-count));
	--top-app-bar-height: 56px;
}
html {
	overflow-x: hidden;
	width: 100vw;
	scroll-behavior: smooth;
	overscroll-behavior: contain;
}

img {
	object-fit: contain;
}

/* Loading */

.loading-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

/* text-overflow */

.text-ellipsis,
.text-ellipsis .MuiListItemText-primay,
.text-ellipsis .MuiListItemText-secondary {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.text-ellipsis--2,
.text-ellipsis--2 .MuiListItemText-secondary {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: normal;
}

.text-ellipsis--3,
.text-ellipsis--3 .MuiListItemText-secondary {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	white-space: normal;
}

/* Icon styles */

.material-icons {
	/* Rules for sizing the icon. */
	&.md-18 {
		font-size: 18px;
	}
	&.md-24 {
		font-size: 24px;
	}
	&.md-36 {
		font-size: 36px;
	}
	&.md-48 {
		font-size: 48px;
	}
	/* Rules for using icons as black on a light background. */
	&.md-dark {
		color: rgba(0, 0, 0, 0.54) !important;
		&.md-inactive {
			color: rgba(0, 0, 0, 0.26);
		}
	}
	/* Rules for using icons as white on a dark background. */
	&.md-light {
		color: rgba(255, 255, 255, 1) !important;
		&.md-inactive {
			color: rgba(255, 255, 255, 0.3);
		}
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Toastify {
	position: fixed;
	z-index: 1500; // material-ui header is 1100
}

.body {
	width: 100%;
	max-width: var(--body-width);
	margin: 0 var(--margin-width);
}

.visually-hidden {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

/* Separate rule for compatibility, :focus-within is required on modern Firefox and Chrome */

input.visually-hidden {
	&:focus + label,
	&:focus-within + label {
		outline: thin dotted;
	}
}

.text--white {
	color: rgba(255, 255, 255, 0.87);
}

.text--italic {
	font-style: italic;
}

.text--bold {
	font-weight: bold;
}

// NE theme
.text--red {
	color: #d44141 !important;
}

.MuiListItemText-secondary {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

@media screen and (min-width: 600px) {
	:root {
		--gutter-width: 24px;
		--margin-width: 32px;
		--column-count: 8;
	}
}

@media screen and (min-width: 905px) {
	:root {
		--margin-width: 0px;
		--body-width: 840px;
		--column-count: 12;
	}
	.body {
		margin: 0 auto;
	}
}

@media screen and (min-width: 1240px) {
	:root {
		--margin-width: 200px;
		--body-width: calc(100vw - (var(--margin-width) * 2));
	}
	.body {
		margin: 0 var(--margin-width);
	}
}

@media screen and (min-width: 1440px) {
	:root {
		--margin-width: 0px;
		--body-width: 1040px;
	}
	.body {
		margin: 0 auto;
	}
}
