.PrivacyPolicy {
	min-height: 86vh;
	margin-bottom: var(--top-app-bar-height);
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (min-width: 600px) {
	.PrivacyPolicy {
		min-height: 98vh;
	}
}
