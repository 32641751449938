.body.WorkspacePicker {
	margin-top: var(--top-app-bar-height);
	padding-top: calc(var(--gutter-width) + 24px);

	.WorkspaceList,
	.continue-as-customer-button {
		width: 600px;
		max-width: 100%;
	}

	.continue-as-customer-button {
		display: flex;
		margin: 0 auto;
		margin-top: 16px;
	}

	.MuiLink-root {
		display: block;
	}
}

@media screen and (min-width: 600px) {
	.body.WorkspacePicker {
		.WorkspaceList {
			margin: 0 auto;
		}
	}
}
