.AuthorizeApp {
	background-color: #fafafa;
	color: #424242;

	.MuiDialog-paper {
		background-color: #fafafa;
		color: #424242;
	}

	.MuiButton-contained.Mui-disabled {
		color: rgba(0, 0, 0, 0.3);
		background-color: rgba(0, 0, 0, 0.12);
	}

	.dialogTitle {
		display: flex;
		align-items: center;
		> * {
			margin-right: 1rem;
		}
	}

	.profile {
		display: flex;
		align-items: center;
		> * {
			margin-right: 1rem;
		}

		padding: 0.5rem;
	}

	.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 150px;
	}
}
