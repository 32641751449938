.CustomerSupportChatFabContainer {
	position: fixed;
	bottom: 2rem;
	right: 2rem;
	z-index: 1300;
	transition: left right 0.25s ease-in-out;

	.CustomerSupportChatFab {
		position: absolute;
		bottom: 0;
		right: 0;
		opacity: 0;
		height: 100%;
		width: 100%;
		border-radius: 24px;
		max-width: calc(100vw - 4rem);
		max-height: calc(100vh - 2rem - var(--top-app-bar-height) - 2rem);
		z-index: 2;
		transform-origin: bottom right 2cm;
		transition: all 0.25s ease-in-out;
		touch-action: none;
		pointer-events: none;
		overflow: hidden;

		&.CustomerSupportChatFab--chatOpen {
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
			animation: chatOut 0.25s ease-in-out forwards;
			transition: none;
			touch-action: auto;
			pointer-events: all;
		}

		&.CustomerSupportChatFab--chatClosed {
			min-height: 48px;
			min-width: 48px;
			animation: chatIn 0.25s ease-in-out forwards;
			touch-action: none;
			pointer-events: none;
		}
	}
}

@media only screen and (max-width: 600px) {
	.CustomerSupportChatFabContainer {
		position: fixed;
		bottom: 1rem;
		right: 1rem;

		&:has(.CustomerSupportChatFab--chatOpen) {
			bottom: 0;
			right: 0;
		}

		.CustomerSupportChatFab {
			max-width: 100vw;
			max-height: 100vh;
			max-height: 100dvh;
		}
	}
}

// animate chat out
@keyframes chatOut {
	0% {
		height: 100%;
		width: 100%;
		opacity: 0;
	}

	25% {
		height: 100%;
		width: 100%;
		opacity: 1;
	}

	100% {
		height: 800px;
		width: 500px;
		border-radius: 8px;
		opacity: 1;
	}
}

// animate chat in
@keyframes chatIn {
	0% {
		height: 800px;
		width: 500px;
		border-radius: 8px;
		opacity: 1;
		background: #fafafa;
	}

	75% {
		opacity: 1;
		background: #fafafa;
	}

	100% {
		opacity: 0;
		height: 100%;
		width: 100%;
		border-radius: 24px;
	}
}