.WorkspaceList {
	.MuiListItem-root {
		padding-left: 0;

		.MuiListItemAvatar-root {
			margin-right: 1rem;

			.MuiAvatar-square {
				width: 100px;
				height: 56px;

				.MuiAvatar-img {
					padding: 4px;
					object-fit: contain;
					background-color: #fafafa;
				}
			}
		}
	}
}
