.CustomerSupportChatInput {
	position: relative;
	.ChatIndicators {
		position: absolute;
		transform: translateY(-20px);

		.dot-typing {
			display: inline-block;
			margin-left: 0.5rem;
		}
	}
}
