.CookieDeclarationScreen {
	min-height: 86vh;
	margin-top: 16px;
	margin-bottom: var(--top-app-bar-height);
}

@media screen and (min-width: 600px) {
	.CookieDeclarationScreen {
		min-height: 82vh;
	}
}
