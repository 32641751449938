.chat-message__text {
	font-size: 0.9rem;
	max-width: 100%;
	white-space: normal;
	word-wrap: break-word;
	width: fit-content;

	img {
		display: block;
		max-width: 50%;
		max-height: 25vh;
		object-fit: contain;
		margin: 1rem auto;
	}

	li {
		margin: 0.5rem 0;
	}

	pre {
		white-space: pre-wrap;
		word-break: break-word;
	}

	a {
		color: inherit;
	}

	table {
		width: 90%;
		margin: 0.5rem auto;
		border-radius: 8px;
		overflow: hidden;

		td {
			padding: 0.5rem;
			border: 1px solid #ccc;
		}

		th {
			padding: 0.5rem;
			border: 1px solid #ccc;
			background-color: #ccc;
		}
	}
}