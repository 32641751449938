.OrderChatConfirmationComponent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem var(--gutter-width);
	text-align: center;
	color: white;
	background-color: #121212;

	img {
		width: calc((var(--column-width) * 2) + (var(--gutter-width) * 3));
		margin-bottom: 16px;
	}

	h4 {
		margin-top: 8px;
		margin-bottom: 20px;
	}

	.download-headline {
		margin-top: 48px;
	}

	.clickable {
		cursor: pointer;
	}

	.order-submitted__text {
		color: white;
	}
}

@media (min-width: 960px) {
	.OrderChatConfirmationComponent {
		h4 {
			margin-bottom: 16px;
		}

		.order-submitted__text {
			width: 482px;
		}
	}
}
