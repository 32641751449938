.chip-group {
	justify-content: space-between;
	display: flex;
	margin-bottom: 10px;
}

.title-field {
	width: 100%;
	margin-bottom: 16px;
}

.date-field {
	flex-grow: 1;
	margin-right: 8px;
}

.time-field {
	min-width: 110px;
}
