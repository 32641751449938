.MapComponent {
	position: relative;
	display: flex;
	width: 100%;

	.map-tiles {
		&.map-tiles--dark {
			filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
		}
	}

	.map-container {
		position: relative;
		width: 100%;
		height: 100%;

		.marker-cluster {
			display: flex;
			justify-content: center;
			align-items: center;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
			font-size: 1rem;
			font-weight: bold;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
			background: rgba(0, 200, 0, 0.5);
			border-radius: 50%;
		}
	}

	.icon-dot {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		transition: background-color 0.3s ease, transform 0.2s ease;
		background: radial-gradient(circle, rgba(0, 0, 255, 0.4), rgba(0, 0, 255, 0.2));
		overflow: visible; /* For å sikre at puls-effekten ikke blir klippet */
	}

	.icon-dot.new::before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 150%; /* Juster for ønsket størrelse på puls-effekten */
		height: 150%;
		border-radius: 50%;
		background: radial-gradient(circle, rgba(0, 0, 255, 0.6), transparent);
		transform: translate(-50%, -50%); /* Sentrer puls-effekten */
		animation: marker-pulse 1.5s infinite;
		transform-origin: center;
	}

	.icon-dot.lead {
		background: radial-gradient(circle, rgba(0, 0, 255, 0.4), rgba(0, 0, 255, 0.2));
	}

	.icon-dot.resolved {
		background: radial-gradient(circle, rgba(255, 165, 0, 0.8), rgba(255, 165, 0, 0.3));
	}

	.icon-dot.converted {
		background: radial-gradient(circle, rgba(0, 255, 0, 0.8), rgba(0, 255, 0, 0.3));
	}
}

@keyframes marker-pulse {
	0% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
	50% {
		transform: translate(-50%, -50%) scale(1.5);
		opacity: 0;
	}
	100% {
		transform: translate(-50%, -50%) scale(1);
		opacity: 1;
	}
}
