.Reaction {
    height: 36px;
    min-width: 36px;
    border-radius: 1rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, .08);
    color: #000 !important;
    .count {
        margin: 0 .5rem;
    }
}