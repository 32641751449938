.FilePreview {
	&.FilePreview--card {
		height: 250px;
		min-width: 150px;
		aspect-ratio: 16/9;
		max-width: 100%;

		img,
		video {
			height: 100%;
			object-fit: cover;
		}
	}

	.text-banner {
		color: white;
		margin-left: 3px;
		margin-right: 3px;
	}
	.header {
		display: flex;
		align-items: center;
		height: 50px;
	}
	.file-author-name {
		margin: 0 8px;
	}

	.card-media-dialog {
		height: calc(100vh - 300px) !important;
		max-height: 100vw;
		object-fit: contain; //scale-down;
	}
	.preview {
		margin: 0;
		height: 100%;
	}
	.dialog {
		padding: 0;
	}
	&:not(:hover) .MuiImageListItemBar-root {
		display: none;
	}
}
