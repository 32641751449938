.NavigationBar {
	.MuiLink-root {
		margin-right: 1rem;
		font-weight: lighter;

		&.NavigationBar__logo {
			display: inline-flex;
			margin-right: 32px;

			img {
				max-height: 36px;
				max-width: 104px;
			}
		}

		&.link--active {
			font-weight: 500;
			text-decoration: underline;
		}
	}

	.spacer {
		flex-grow: 1;
	}

	.ProfilePicture {
		width: 32px;
		height: 32px;
	}
}
