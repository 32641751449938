.FullScreenDialog {
	overscroll-behavior-y: none;
	overscroll-behavior-x: none;
	z-index: 1100;
	* {
		overscroll-behavior-y: none;
		overscroll-behavior-x: none;
	}

	.MuiAppBar-root {
		.title {
			flex-grow: 1;
			margin-left: 20px;
		}
	}
}
