.customer-support-chat-message {
	font-size: 0.9rem;
	.message-content {
		height: fit-content;
		line-height: 1.3;
		img {
			width: 80%;
			max-height: 400px;
			display: block;
			margin: 1rem auto;
		}
	}
}
