.CookieConsent {
	position: fixed;
	left: calc(50% - var(--gutter-width));
	bottom: 0;
	margin: var(--gutter-width);
	z-index: 1000;
	background-color: rgba(255, 255, 255, 0.5) !important;
	backdrop-filter: blur(10px);
	transform: translateX(calc(-50% + var(--gutter-width)));

	.cookie-actions {
		display: flex;
		justify-content: flex-end;
	}
}

@media screen and (max-width: 905px) {
	.CookieConsent {
		width: 90vw;
		left: 5vw;
		bottom: 10vw;
		transform: none;
		margin: 0;

		&.CookieConsent--quote {
			bottom: auto;
			top: 100px;
		}

		// width: -webkit-fill-available;
	}
}
