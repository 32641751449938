.dot-typing {
	text-align: center;
}
.dot-typing span {
	display: inline-block;
	vertical-align: middle;
	width: 10px;
	height: 10px;
	background: black;
	border-radius: 10px;
	animation: dot-typing 0.8s infinite alternate;
}
.dot-typing span:nth-of-type(2) {
	animation-delay: 0.2s;
}
.dot-typing span:nth-of-type(3) {
	animation-delay: 0.6s;
}
@keyframes dot-typing {
	0% {
		opacity: 0.9;
		transform: scale(0.5);
	}
	100% {
		opacity: 0.1;
		transform: scale(1);
	}
}
